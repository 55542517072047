/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-backward-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-4.79-2.907L8.5 7.028V5.5a.5.5 0 00-.79-.407L5 7.028V5.5a.5.5 0 00-1 0v5a.5.5 0 001 0V8.972l2.71 1.935a.5.5 0 00.79-.407V8.972l2.71 1.935A.5.5 0 0012 10.5v-5a.5.5 0 00-.79-.407"/>',
    },
});
